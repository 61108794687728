import { unstable_ClassNameGenerator as ClassNameGenerator } from '@mui/material/className';
ClassNameGenerator.configure((componentName) => componentName.replace('Mui', 'V5')); // load as early as possible this code in a js file
/* eslint-disable react/prop-types */
import React from 'react';
import { MUIProvider } from '@next-common/mui';
import { createStore, applyMiddleware, combineReducers } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import paymentMeanDetailsModal from '@next-common/payment-mean-details-modal-v5';
import { mdModule } from '@next-common/breakpoint';
import { Wrapper } from '../../fragmentHelper/Wrapper';
import { renderReact } from '../../fragmentHelper/renderReactCustom';
import { hypernovaWrapperClassName } from '../../utils/hypernovaWrapperClassName';

const { PaymentMeanDetailsModalContainer }: any = paymentMeanDetailsModal.containers;
const paymentMeanDetailsModalReducer = paymentMeanDetailsModal.reducer;
const mdReducer = mdModule.reducer;
type PagePropsType = {
  moduleName: string;
};
export const moduleName = 'PaymentMeanDetail';
export const serverCanCache = true;
const WrappedPaymentMeanDetailsModal: React.FC<PagePropsType> = ({ moduleName }) => {
  const createStoreWithMiddleware = applyMiddleware(thunk)(createStore);
  const reducer = combineReducers({
    paymentMeanDetailsModal: paymentMeanDetailsModalReducer,
    md: mdReducer,
  });
  const store = createStoreWithMiddleware(reducer);
  const htmlFontSize = 10; // SSR default font Size, used in Canopy
  // us a css based dynamic css fontsize ajust as in css files transpiled by the postcss pluging where all rem values get ajusted see 'postcss-rem-to-rem-multiplier'
  // this is needed because different monolith pages have different <html> fontsize values. e.g. 13px, 12px, 16px.
  // a js in monolith is setting the css var --hyp-rem-multi earl in the page load, this react js is loaded later
  // css will look like this calc(1.2rem * var(--hyp-rem-multi))
  const pxToRem = (size: number): string => `calc(${size / htmlFontSize}rem * var(--hyp-rem-multi))`;
  return (
    <Provider store={store}>
      <MUIProvider moduleName={moduleName} htmlFontSize={htmlFontSize} pixToRem={pxToRem} prefixwrapClassName={hypernovaWrapperClassName}>
        <Wrapper>
          <PaymentMeanDetailsModalContainer />
        </Wrapper>
      </MUIProvider>
    </Provider>
  );
};
export const renderFunction = renderReact('PaymentMeanDetails', WrappedPaymentMeanDetailsModal);